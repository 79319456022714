// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rqdY4HKBBe3G08vYCS9L{align-items:center;background-color:rgba(0,0,0,.5);display:flex;height:100%;justify-content:center;left:0;position:fixed;top:0;width:100%}.uqpygWfVMHPBV5hkSLb1{background-color:#fff;border-radius:5px;box-shadow:0 0 10px rgba(0,0,0,.5);padding:20px}.Q2csn29XeldLuI92vJ7y button{margin-right:20px}.bksBy7VNRKTiFoGGOY9G{background:transparent;border:none;color:#333;cursor:pointer;font-size:20px;position:absolute;right:10px;top:10px}`, "",{"version":3,"sources":["webpack://./src/pages/Opsmile/Opsmile.module.css"],"names":[],"mappings":"AAAA,sBASI,kBAAmB,CAHnB,+BAAoC,CACpC,YAAa,CAFb,WAAY,CAGZ,sBAAuB,CALvB,MAAO,CAFP,cAAe,CACf,KAAM,CAEN,UAMJ,CACA,sBACI,qBAAuB,CAEvB,iBAAkB,CAClB,kCAA2C,CAF3C,YAGJ,CACA,6BACI,iBACJ,CACA,sBAII,sBAAuB,CACvB,WAAY,CAGZ,UAAW,CAFX,cAAe,CACf,cAAe,CANf,iBAAkB,CAElB,UAAW,CADX,QAOJ","sourcesContent":[".popupOverlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.popup {\n    background-color: white;\n    padding: 20px;\n    border-radius: 5px;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);\n}\n.buttons button{\n    margin-right: 20px;\n}\n.closeButton {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    background: transparent;\n    border: none;\n    cursor: pointer;\n    font-size: 20px;\n    color: #333;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupOverlay": `rqdY4HKBBe3G08vYCS9L`,
	"popup": `uqpygWfVMHPBV5hkSLb1`,
	"buttons": `Q2csn29XeldLuI92vJ7y`,
	"closeButton": `bksBy7VNRKTiFoGGOY9G`
};
export default ___CSS_LOADER_EXPORT___;
